import objectPath from "object-path";
import distribuidorConfig from "@/core/config/DistribuidorConfig";
import { Action, Module, VuexModule } from "vuex-module-decorators";
import LayoutConfigTypes from "@/core/config/LayoutConfigTypes";

interface StoreInfo {
  config: LayoutConfigTypes;
  initial: LayoutConfigTypes;
}

const host = window.location.host;
//const host = 'makena.inservice.app';
let distribuidor = distribuidorConfig.filter((e) => e.dominio_gestao == host)[0];
// console.log(distribuidor);

if (!distribuidor) {
  distribuidor = distribuidorConfig[0];
}

document.documentElement.setAttribute(
  "theme",
  distribuidor.theme ?? distribuidor.distribuidor
);

const layoutConfig: LayoutConfigTypes = {
  themeName: "Metronic",
  themeVersion: "8.0.28",
  demo: "demo1",
  main: {
    type: "default",
    primaryColor: "#009EF7",
    logo: {
      dark: "./distribuidor/icone_" + distribuidor.distribuidor + ".png",
      light: "./distribuidor/icone_" + distribuidor.distribuidor + ".png",
    },
  },
  loader: {
    logo: "./distribuidor/icone_" + distribuidor.distribuidor + ".png",
    display: true,
    type: "spinner-logo",
  },
  scrollTop: {
    display: true,
  },
  header: {
    display: true,
    menuIcon: "svg",
    width: "fluid",
    fixed: {
      desktop: true,
      tabletAndMobile: true,
    },
  },
  toolbar: {
    display: false,
    width: "fluid",
    fixed: {
      desktop: true,
      tabletAndMobile: true,
    },
  },
  aside: {
    display: true,
    theme: "light",
    fixed: true,
    menuIcon: "svg",
    minimized: true,
    minimize: true,
    hoverable: true,
  },
  content: {
    width: "fixed",
  },
  footer: {
    width: "fixed",
  },
  distribuidor: distribuidor,
};

@Module
export default class ConfigModule extends VuexModule implements StoreInfo {
  config = layoutConfig;
  initial = layoutConfig;

  /**
   * Get config from layout config
   * @returns {function(path, defaultValue): *}
   */
  get layoutConfig() {
    return (path, defaultValue) => {
      return objectPath.get(this.config, path, defaultValue);
    };
  }

  @Action
  setContentType(type) {
    this.config.content.width = type;
  }
}
