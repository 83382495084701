
import { defineComponent } from "vue";

//import '@/assets/sass/distribuidor/atria.scss';
export default defineComponent({
  name: "app",

  setup() {
    window.onbeforeunload = function(){
        return localStorage.removeItem('lojaFixada')
    };
  },
});
