import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import store from "@/store/index";
import moment from "moment";
import { Actions } from "@/store/enums/StoreEnums";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description decode jwt para pegar os valores do token
   */
  public static parseJwt(token) {
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  /**
   * @description valida se o token salvo ja expirou, se expirou tenta logar novamente
   */
  public static async valideTokenTime(): Promise<boolean> {
    let auth = false;
    let token = JwtService.getToken();
    if (token) {
      let tokenDecode = this.parseJwt(token);
      let time = moment().unix();
      // console.log('agora',time);
      // console.log('exp',tokenDecode.exp);
      if (time > tokenDecode.exp) {
        // console.log('temos que refazer o token');
        auth = await store.dispatch(Actions.LOGADO);
      } else {
        auth = true;
      }
      // console.log('tokenDecode',tokenDecode.exp);
    }

    return auth;
  }

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    // const host_api = store.getters.layoutConfig("distribuidor.dominio_api");
    // const host_http = store.getters.layoutConfig("distribuidor.dominio_gestao") == 'localhost:8080' ? 'http' : 'https';
    // const host_http = 'https';

    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_URL_API
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params).catch((error) => {
      throw new Error(`[CAMP] ApiService ${error}`);
    });
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static async get(
    resource: string,
    slug = "" as string,
    valid = true
  ): Promise<AxiosResponse> {
    // this.setHeader();
    // if(valid)
    // {
    //   await this.valideTokenTime();
    // }

    return ApiService.vueInstance.axios
      .get(`${resource}/${slug}`)
      .catch((error) => {
        throw new Error(
          `ErrorPath: ${resource}/${slug} ;[KT] ApiService ${error}`
        );
      });
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static async post(
    resource: string,
    params: AxiosRequestConfig,
    valid = true
  ): Promise<AxiosResponse> {
    // this.setHeader();
    // if(valid)
    // {
    //   await this.valideTokenTime();
    // }

    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    // this.setHeader();
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static async put(
    resource: string,
    params: AxiosRequestConfig,
    valid = true
  ): Promise<AxiosResponse> {
    // this.setHeader();
    // if(valid)
    // {
    //   await this.valideTokenTime();
    // }

    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
}

export default ApiService;
