import { Mutations } from "@/store/enums/StoreEnums";
import { UsuarioModel } from "@/models/UsuarioModel";
import { ResponseReq } from "@/models/ResponseReq";
import ApiService from "./ApiService";
import store from "@/store";

const USER = "user" as string;
const TOKEN_USER = "token_user" as string;

export const logarUsuario = (credentials): any => {
  return ApiService.post("login", credentials);
};

/**
 * @description get usuario form localStorage
 *
 *
 */

export const getUsuario = (): any => {
  const user: any = window.localStorage.getItem(USER);
  return user;
};

export const getUsuarioAuth = (): UsuarioModel => {
  return {} as UsuarioModel;
};

/**
 * @description save usuario into localStorage
 * @param usuario: UsuarioModel
 */
export const saveUsuarioAuth = async (usuario: UsuarioModel) => {
  const user = usuario;
  const dadosSensiveis = ["documento", "email_verified_at", "resetarSenha"];
  await dadosSensiveis.forEach((dados) => {
    delete user[dados];
  });

  window.localStorage.setItem(USER, JSON.stringify(user));
};

/**
 * @description remove usuario form localStorage
 */
export const destroyUsuarioAuth = (): void => {
  window.localStorage.removeItem(USER);
  window.localStorage.removeItem(TOKEN_USER);
};

export default {
  getUsuarioAuth,
  saveUsuarioAuth,
  destroyUsuarioAuth,
  logarUsuario,
  getUsuario,
};
