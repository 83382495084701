import { AutenticacaoModel } from "@/models/AutenticacaoModel";
import { UsuarioModel } from "@/models/UsuarioModel";
import ApiService from "@/services/ApiService";
import AuthService from "@/services/AuthService";
import JwtService from "@/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store/index";
import router from "@/router";

@Module
export default class AuthModule
  extends VuexModule
  implements AutenticacaoModel
{
  errors = [];
  usuario = {} as UsuarioModel;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */

  get usuarioLogado(): any {
    return this.usuario;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUsuarioLogado(): boolean {
    return this.isAuthenticated;
  }

  /**
   * return user nivelCargo
   * @returns number
   */

  get getNivelCargo(): number {
    let nivelCargo;

    if (this.isUsuarioLogado) {
      nivelCargo = this.usuario.nivelCargo;
    }

    return nivelCargo;
  }

  /**
   * Get authentification errors
   * @returns array
   */

  get getErrors(): Array<string> {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](auth) {
    this.isAuthenticated = true;
    this.usuario.id = auth.id;
    this.usuario.email = auth.email;
    this.usuario.nome = auth.name;
    this.usuario.telefone = auth.telefone;
    this.usuario.imgUsuario = auth.imgUsuario;
    this.usuario.idEmpresa = auth.idEmpresa;
    this.usuario.idCargo = auth.idCargo;
    this.usuario.nivelCargo = auth.nivelCargo;
  }

  @Mutation
  [Mutations.DELETE_AUTH]() {
    JwtService.destroyToken();
    AuthService.destroyUsuarioAuth();
  }

  @Action
  [Actions.AUTENTICACAO](credentials) {
    return new Promise<any>((resolve, reject) => {
      AuthService.logarUsuario(credentials)
        .then(({ data }) => {
          // console.log(data);
          if (data.error) {
            this.context.commit(Mutations.SET_ERROR, data.message);
            reject();
          }
          JwtService.saveToken(data.data.token);
          AuthService.saveUsuarioAuth(data.data.usuario);
          this.context.commit(Mutations.SET_AUTH, data.data.usuario);

          return resolve(data);
        })
        .catch((err) => console.log(err));
    });
  }

  @Action
  [Actions.LOGOUT]() {
    // console.log('action LOGOUT');
    this.context.commit(Mutations.DELETE_AUTH);
    router.push({ name: "login" });
    // console.log('redireciona para login');
  }

  @Action
  [Actions.CLEAR]() {
    // console.log('clear action');
    this.context.commit(Mutations.DELETE_AUTH);
  }

  @Action
  [Actions.LOGADO]() {
    const user = AuthService.getUsuario();
    const token = JwtService.getToken();

    // console.log("user", user);
    // console.log("token", token);

    if (user && token) {
      this.context.commit(Mutations.SET_AUTH, JSON.parse(user));
      return;
    }

    this.context.commit(Mutations.DELETE_AUTH);
  }
}
