import { Actions, Mutations } from "@/store/enums/StoreEnums";
import AuthService from "@/services/AuthService";
import router from "../router";
import store from "../store";
import JwtService from "./JwtService";

export default {
  redirectAuthenticatedUser(to, from) {
    const token = JwtService.getToken();
    if (token) {
      router.push({ name: "filter" });
    }
  },
  authenticateuser(to, from) {
    const token = JwtService.getToken();
    const user = AuthService.getUsuario();
    if (!token || !user) {
      store.dispatch(Actions.LOGOUT);
      return;
    }

    store.dispatch(Actions.LOGADO);
  },
};
